<template>
  <div class="p-4">
    <b-loading
      :is-full-page="false"
      v-model="isLoading"
      :can-cancel="false"
    />
    <div
      :class="[messages.main_color, 'info-line']"
    />
    <div class="column p-0 is-size-6 tag-parent-mobile is-flex is-flex-wrap-wrap">
      <span
        class="tag-mobile is-size-6 tag px-4 py-4 mr-3 mb-2"
        v-for="(message) in messages.cards"
        :key="message.id"
        :class="message.color"
      >{{ message.name }}</span>
    </div>
    <div
      id="chartdiv"
      ref="chartdiv"
    />
  </div>
</template>

<script>
import * as am4core from "@amcharts/amcharts4/core"
import * as am4charts from "@amcharts/amcharts4/charts"
import am4lang_ru_RU from "@amcharts/amcharts4/lang/ru_RU"

export default {
  name: "TrendsOil",
  props: ["device_id"],
  data() {
    return {
      isLoading: true,
      messages: {
        main_color: "#000000",
      },
      oils: [],
      oilNames: []
    }
  },
  mounted() {
    let chart = am4core.create(this.$refs.chartdiv, am4charts.XYChart)
    chart.language.locale = am4lang_ru_RU

    let dateAxis = chart.xAxes.push(new am4charts.DateAxis());
    dateAxis.renderer.grid.template.location = 0;
    dateAxis.renderer.minGridDistance = 80;
    dateAxis.startLocation = 0.5;
    dateAxis.endLocation = 0.5;

    const valueAxis = this.createValueAxis(chart, "°C");

    this.fetchMessages()
    this.fetchOils().then(() => {
      chart.data = this.oils
      this.oilNames.forEach((name, idx) => {
        if (['system_temperature', 'coil_temperature'].includes(name)) {
          this.createSeries(chart, `${name}_0`, name, idx, valueAxis)
        } else {
          this.createSeries(chart, `word_0_Cnt${idx+1}`, name, idx, valueAxis)
        }
      })
    })

    chart.legend = new am4charts.Legend();
    chart.legend.scrollable = true;
    chart.legend.maxHeight = 150;
    chart.legend.marginBottom = 20;
    // chart.legend.position = "top";
    let markerTemplate = chart.legend.markers.template;
    markerTemplate.width = 15;
    markerTemplate.height = 10;

    //create event to cursor
    chart.cursor = new am4charts.XYCursor();
    chart.cursor.maxTooltipDistance = -1;
    chart.cursor.behavior = "zoomX";
    chart.cursor.xAxis = dateAxis;
  },
  methods: {
    async fetchMessages() {
      try {
        const { data } = await this.$axios.get(`user/agents/${this.device_id}/last-message`)
        this.messages = data?.data || []
      } catch (e) {
        throw new Error(e)
      }
    },
    async fetchOils() {
      this.isLoading = true
      let from = new Date()
      let to = Date.now()

      from = from.setDate(from.getDate() - 1)

      try {
        const { data } = await this.$axios.post(`user/agents/${this.device_id}/oils`, {
          to,
          from,
        })
        this.oils = data?.data || []
        this.oilNames = data?.names || []
      } catch (e) {
        throw new Error(e)
      }
      this.isLoading = false
    },
    createSeries(chart, field, name, zIndex, valueAxis, color, hiddenInLegend = false, connected = true) {
      if (chart) {
        const series = chart.series.push(new am4charts.LineSeries());
        series.dataFields.valueY = field;
        series.dataFields.dateX = "time";
        series.name = name;
        series.id = `${field}-${zIndex}`;
        // series.connect = false;
        series.hiddenInLegend = hiddenInLegend;
        series.sequencedInterpolation = hiddenInLegend;
        // series.connect = connected;
        // series.autoGapCount = 5

        if (color) {
          series.tooltip.fillcolor = color;
          series.stroke = color;
        }
        if (["alarm", "warning"].includes(field)) {
          series.strokeDasharray = field === "warning" ? "8,4" : "0";
          series.hiddenInLegend = true;
        }
        series.tooltipText = "{dateX}: [b]{valueY}[/]";
        series.adapter.add("tooltipText", function(text) {
          text = "[bold]{dateX}[/]\n";
          chart.series.each(function(item) {
            if ( !item.isHidden && !["alarm", "warning"].includes(item.dataFields.valueY)) {
              text += "[" + item.stroke.hex + "]●[/] " + item.name + ": [bold]{" + item.dataFields.valueY + "}[/]\n";
            }
          });
          return text;
        });
        series.tooltip.getFillFromObject = false;
        series.tooltip.background.fill = am4core.color("#fff");
        series.tooltip.label.fill = am4core.color("#00");
        series.strokeWidth = 2;
        series.zIndex = zIndex;
        series.yAxis = valueAxis;
        series.chart = chart;
        series.tensionX = 0.8;
        // series.bullets.push(new am4charts.CircleBullet());
        // series.cursorTooltipEnabled = false;

        return series;
      }
    },

    createValueAxis(chart, text, fontWeight = "600", isOpposite = false) {
      if (chart) {
        let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
        valueAxis.renderer.opposite = isOpposite;
        valueAxis.showOnInit = true;
        valueAxis.title.text = text;
        valueAxis.title.rotation = 90;
        valueAxis.title.valign = "top";
        valueAxis.title.fontWeight = fontWeight;
        valueAxis.renderer.grid.template.strokeOpacity = 0.1;
        // if(chart.yAxes.indexOf(valueAxis) !== 0){
        //   valueAxis.syncWithAxis = chart.yAxes.getIndex(0);
        // }

        return valueAxis;
      }
      return null;
    },
  },
}
</script>

<style scoped>

</style>
